import { config } from '@client/src/config';
import type { Metadata } from 'next';
import type { Key } from 'path-to-regexp';
import { compile, parse } from 'path-to-regexp';
import PlaceholderIllustration from 'public/assets/illustrations/illustration_dashboard.webp';

import { RequireCurrentProps } from '../decorators/RequireCurrentProps';
import { RequireParams } from '../decorators/RequireParams';
import { comparePathnames } from '../helpers/comparePathnames';
import type {
  AvailableLocales,
  AvailableRoutes,
  ParamsFromPath,
  ParamValueProp,
  Path,
  PathParams,
  RouteConfig,
  RouteMode,
  RouteParams,
  SearchParams,
} from '../types';

interface RouteDescriptorConstructorParams<
  Locales extends AvailableLocales,
  Path extends AvailableRoutes<Locales>,
> {
  path: Path;
  config: RouteConfig<Locales>;
}

export class RouteDescriptor<
  Locales extends AvailableLocales,
  RoutePath extends AvailableRoutes<Locales>,
> {
  private readonly _path: RoutePath;
  private readonly _mode: RouteMode;
  private readonly _index: boolean;
  private readonly _sitemap: boolean;
  private readonly _locales: Set<Locales[number]>;

  private _params: PathParams<RoutePath>;
  private _searchParams: SearchParams = {};

  private _current: {
    locale?: AvailableLocales[number];
    path?: Path;
  } = { locale: 'en' };

  constructor({
    path,
    config,
  }: RouteDescriptorConstructorParams<Locales, RoutePath>) {
    this._path = path;

    this._mode = config.mode;
    this._index = config.index;
    this._sitemap = config.sitemap;

    const locales = new Set<AvailableLocales[number]>();
    config.locales.forEach((locale) => locales.add(locale));
    this._locales = locales;

    const params = (
      parse(this._path).filter(
        (item: object | string) => typeof item === 'object',
      ) as Key[]
    ).reduce(
      (acc, { name, modifier }) => ({
        ...acc,
        [name]: { isRequired: modifier !== '?' },
      }),
      {} as PathParams<RoutePath>,
    );

    this._params = params;
  }

  get path() {
    return this._path;
  }

  get isPublic() {
    return this._mode === 'public';
  }

  get isProtected() {
    return this._mode === 'protected';
  }

  get isIndexable() {
    return this._index;
  }

  get isIncludedInSitemap() {
    return this._sitemap;
  }

  get locales() {
    return [...this._locales];
  }

  get hasOnlyDefaultLocale() {
    if (this._locales.size > 1) return false;
    return this._locales.has(config.i18n.default);
  }

  get params() {
    return this._params;
  }

  @RequireParams
  get href(): Path {
    const isCurrentLocaleAvailable = (
      this._locales as Set<AvailableLocales[number] | undefined>
    ).has(this._current.locale);
    const localeForRoute = isCurrentLocaleAvailable
      ? this._current.locale!
      : config.i18n.default;

    const translations = require(
      `src/i18n/translations/${localeForRoute}.json`,
    ) as { routes: Record<Path, { metadata?: { route?: Path } }> };

    const validPath =
      translations.routes[this._path]?.metadata?.route ?? this._path;

    return `/${localeForRoute}${this.buildHref(validPath)}`;
  }

  @RequireParams
  get absoluteHref() {
    return `${config.app.baseUrl}${this.href}`;
  }

  @RequireParams
  @RequireCurrentProps('path')
  get isActive() {
    return comparePathnames(this.href, this._current.path!).segmentsEquality;
  }

  @RequireParams
  @RequireCurrentProps('path')
  get isExactlyActive() {
    return comparePathnames(this.href, this._current.path!).fullEquality;
  }

  set currentLocale(locale: AvailableLocales[number]) {
    this._current.locale = locale;
  }

  set currentPath(path: Path) {
    this._current.path = path;
  }

  async getAbsoluteHref(locale: Locales[number]) {
    const translations = (await import(
      `src/i18n/translations/${locale}.json`
    )) as { routes: Record<Path, { metadata?: { route?: Path } }> };

    const routePath =
      translations.routes[this._path]?.metadata?.route ?? this._path;

    return `${config.app.baseUrl}/${locale}${this.buildHref(routePath)}`;
  }

  async getAllPaths_async() {
    const availableLocalesPaths = config.i18n.locales.reduce(
      async (promiseAcc, locale) => {
        const translatedLocale = (await import(
          `src/i18n/translations/${locale}.json`
        )) as { routes: Record<Path, { metadata?: { route?: Path } }> };

        const acc = await promiseAcc;

        return {
          ...acc,
          [locale]:
            translatedLocale.routes[this._path]?.metadata?.route ?? this._path,
        };
      },
      {} as Promise<Record<AvailableLocales[number], Path>>,
    );

    return availableLocalesPaths;
  }

  getAllPaths_sync() {
    const availableLocalesPaths = config.i18n.locales.reduce(
      (acc, locale) => {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const translatedLocale = require(
          `src/i18n/translations/${locale}.json`,
        ) as { routes: Record<Path, { metadata?: { route?: Path } }> };

        return {
          ...acc,
          [locale]:
            translatedLocale.routes[this._path]?.metadata?.route ?? this._path,
        };
      },
      {} as Record<AvailableLocales[number], Path>,
    );

    return availableLocalesPaths;
  }

  @RequireCurrentProps('path')
  async generateMetadata(canonical: Path = '/'): Promise<Metadata> {
    const { title, description, keywords } = ((
      (await import(`src/i18n/translations/${this._current.locale}.json`)) as {
        routes: Record<Path, { metadata?: any }>;
      }
    ).routes[this._current.path!]?.metadata ?? {}) as {
      title?: string;
      description?: string;
      keywords?: string;
    };

    return {
      metadataBase: new URL(await this.getAbsoluteHref(this._current.locale!)),
      title: title && `${title} | ${config.app.name}`,
      description,
      keywords,
      alternates: { canonical },
      openGraph: {
        title: title && `${title} | ${config.app.name}`,
        description,
        url: '/',
        siteName: config.app.name,
        type: 'website',
        locale: this._current.locale,
        alternateLocale: [...this._locales].filter(
          (locale) => locale !== config.i18n.default,
        ),
        images: `${config.app.baseUrl}${PlaceholderIllustration.src}`,
      },
      twitter: {
        card: 'summary',
        title: title && `${title} | ${config.app.name}`,
        description,
        site: config.app.name,
        images: `${config.app.baseUrl}${PlaceholderIllustration.src}`,
      },
      robots: {
        index: this._index,
        follow: true,
      },
    };
  }

  setParams(
    params: PathParams<RoutePath, ParamsFromPath<RoutePath>, ParamValueProp>,
  ) {
    const passedParams = (
      Object.entries(params) as [keyof PathParams<RoutePath>, ParamValueProp][]
    ).reduce(
      (acc, [paramName, value]) => ({
        ...acc,
        [paramName]: { ...this._params[paramName], value },
      }),
      {} as PathParams<RoutePath>,
    );

    const updatedParams = { ...this._params, ...passedParams };
    this._params = updatedParams;

    return this;
  }

  setSearchParams(params: SearchParams) {
    this._searchParams = params;
    return this;
  }

  private encodeParams() {
    return Object.entries<RouteParams>(this._params).reduce(
      (acc, [paramName, { value }]) => ({
        ...acc,
        [paramName]: typeof value === 'number' ? +value : value ?? undefined,
      }),
      {} as PathParams<RoutePath, ParamsFromPath<RoutePath>, ParamValueProp>,
    );
  }

  private encodeSearchParams() {
    const searchParamsString = Object.entries(this._searchParams).reduce(
      (resultString, [param, value]) =>
        resultString.concat(param, '=', value.toString()),
      '?',
    );

    return searchParamsString === '?' ? '' : searchParamsString;
  }

  private buildHref(path: Path) {
    return compile(path, { encode: encodeURIComponent })(
      this.encodeParams(),
    ).concat(this.encodeSearchParams()) as Path;
  }
}
