/* This needs to be process.env and not an imported value or it will crash because of Next's shenanigans. */
const port = process.env.PORT ?? 3001;
const env = process.env.VERCEL_ENV ?? process.env.NODE_ENV;

export const config = {
  env,
  port,
  app: {
    name: '1 Security',
    baseUrl:
      env === 'development'
        ? `http://localhost:${port}`
        : `https://${process.env.NEXT_PUBLIC_VERCEL_URL}`,
  },
  i18n: {
    locales: ['en', 'pl', 'de'],
    default: 'en',
  },
} as const;
