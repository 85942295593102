// eslint-disable-next-line import/no-extraneous-dependencies
import { protectedCollection } from '@client/src/routes/protected';
import 'dotenv/config';

const config = {
  nextAuthSecret: "secret!c9e46d40-a87a-489f-8368-858222c27c36",
  clientUrl: "https://dev.1.security",
  azureAdB2cTenantName: "codeally",
  azureAdB2cClientId: "ae111b13-168b-4283-93e1-ec75ec1e018f",
  azureAdB2cClientSecret: 'Lbt8Q~53tzPzgKOb4USNn8tGpt4-lwDZNRJA6cvY',
  azureAdB2cPrimaryUserFlow: "B2C_1_codeally",
  refreshTokenUrl: "https://devapi.1.security/refresh-token",
  accessTokenSecret: "3f413e86f5159d3958f4fb4e027b1756d97e76212649d5633618c7fe5292328249f2",
  refreshTokenSecret: "7e907a4b2d6570d0dbf8ba547cf3cca7f353cfa20488745e3c9e51febca95cd2b649",
  graphqlUrl: "https://devapi.1.security/graphql",
  nextPublicGraphqlUrl: "https://devapi.1.security/graphql",
  nextPublicApiTokens: "https://dev.1.security/api/tokens",
  googleOAuthClientId: "492127179116-3rhhf1gbjfrlk350cp90kmv7c6eom360.apps.googleusercontent.com",
  googleOAuthSecret: "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",
  nextPublicReactQueryDevtools: "enabled" || true,
  env: 'production',
  pathAfterLogin: protectedCollection.routes['/dashboard'].href,
  reactQueryDevtools: "enabled",
} as const;

export const AZURE_AD_CONFIG = {
  clientId: "4f47af7d-b3ee-4e10-81aa-4b4039c60412"!,
  clientSecret: '2J98Q~YqZX1JgwN6dSOhx8i2jJwSZGfolAA~gbZ9'!,
  tenantId: "organizations"!,
};

export default config;
